/* 7group.aero */

// General Styles
html { height: 100%; background: $sg-black; }
body { text-rendering: optimizeLegibility; position: relative; min-height: 100%; }

/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7f7f7+0,ffffff+42 */
body {
  background: $sg-white; 
  @include gradient-vertical($start-color: $sg-white, $end-color: #ffffff, $start-percent: 0%, $end-percent: 42%);
  background-attachment: fixed;
  &.home { background: transparent !important; height: 100%; filter: none; }
}

// Responsive Behaviour & Tidbits
img { max-width: 100%; height: auto; }
.centered { text-align: center; }
@media (max-width: $screen-xs-max) {
  .centered-xs {
    text-align: center !important;  
  }
}
.btn-image { display: block; 
  margin-left: -15px; margin-right: -15px;
}
.homerun { display: block;
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { margin-top: -114px; padding-right: 33px; height: 265px; }
  @media (min-width: $screen-lg-min) { margin-left: -15px; margin-top: -114px; height: 265px; }
}
.grayline { padding-bottom: 42px; border-bottom: 2px solid $sg-3; }
.verticallycentered { position: absolute; width: 100%; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); }
.left { text-align: left; }
.right { text-align: right; }
.hidden { display: none; }
.hyphenated { -webkit-hyphens: auto; -moz-hyphens: auto; -ms-hyphens: auto; -o-hyphens: auto; hyphens: auto; }
.justified { text-align: justify; }
.thumb { display: inline-block; max-width: 100%; position: relative; }
.noselect { -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

// Lazy Load
// https://github.com/aFarkas/lazysizes/blob/gh-pages/README.md
#gallery { min-height: 777px;
  .lazyload, .lazyloading { opacity: 0.42; }
  .lazyloaded { opacity: 1; transition: opacity 300ms; }
}

// Modifier Classes for Fixed Aspect Ratios
.embed-responsive-10by3 { padding-bottom: 30%; }
.embed-responsive-8by3 { padding-bottom: 37.5%; }
.embed-responsive-42 { padding-bottom: 42%; }
.embed-responsive-2by1 { padding-bottom: 50%; }
.embed-responsive-3by2 { padding-bottom: 66.667%; }

.embed-responsive-5by4 { padding-bottom: 80%; }
.embed-responsive-1by1 { padding-bottom: 100%; }
.embed-responsive-4by5 { padding-bottom: 125%; }
.embed-responsive-2by3 { padding-bottom: 150%; }

// Links
a, a:link { color: $sg-blue; text-decoration: none; }
a.btn, a.btn:link { color: $sg-white; }
a:focus, a:hover { color: $sg-blue-light; text-decoration: underline; }
p, ul {
  a, a:link { text-decoration: none; }
  a:focus, a:hover { text-decoration: underline; }
}
p, ul { 
  a.tel, a.tel:link { text-decoration: none; }
  a.tel:focus, a.tel:hover { text-decoration: underline; }
}

// Headings
.page-header { border-bottom: none; margin-top: 42px; margin-bottom: 0; padding-bottom: 0; }
h1, .h1 { font-family: $font-family-allumi; font-style: italic; font-weight: 500; letter-spacing: -0.01em;
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { margin-left: -43px; }
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { margin-left: -61px; }
  @media (min-width: $screen-lg-min) { margin-left: -98px; }
  #intro & { margin-left: 0 !important; }
}

// Main Titles
h2, .h2 { font-weight: 700; font-size: 23px; font-family: $font-family-allumi; font-style: italic; margin-top: 42px; margin-bottom: 17px; letter-spacing: 0.02em; line-height: 27px;
  @media (min-width: $screen-sm-min) { margin-left: -30px; }
}

// Titles
h3, .h3 { font-weight: 700; font-size: 17.7px; margin-top: 42px; margin-bottom: 14px; letter-spacing: 0.014em; line-height: 27px; }
h2 + h3, .h2 + h3, h2 + .h3, .h2 + .h3 { margin-top: -0; }

// Subtitles
h4, .h4 { font-weight: 700; font-size: $font-size-base; line-height: 24px; margin-top: 28px; margin-bottom: 7px; letter-spacing: 0.014em; }
h3 + h4, .h3 + h4, h3 + .h4, .h3 + .h3 { margin-top: -7px; }

// Big Red Headings
h1.red, .h1.red { color: $sg-red; font-family: $font-family-allumi; font-style: italic; font-weight: 700; margin-left: -30px; margin-top: 70px; text-transform: uppercase; font-size: 35px; line-height: 42px;
  @media (max-width: $screen-xs-max) { margin-left: 0; font-size: 23px; line-height: 35px; margin-bottom: 7px; }
}
h2.red, .h2.red { color: $sg-red-dark; font-family: $font-family-allumi; font-size: 16px; font-style: normal; text-transform: none; font-weight: 300; margin-bottom: 42px;
  @media (max-width: $screen-xs-max) { margin-left: 0; font-size: 15px; line-height: 25px; }
}
h1.red + h2.red, .h1.red + h2.red, h1.red + .h2.red, .h1.red + .h2.red { margin-top: 7px; margin-left: -28px;
  @media (max-width: $screen-xs-max) { margin-left: 0; }
}

// Sub-Titles for Big Red Headings
h5, .h5 { color: $sg-black; font-family: $font-family-allumi; font-size: 16px; font-style: normal; text-transform: uppercase; font-weight: 300; margin-bottom: 42px; letter-spacing: 0.02em; }
h1.red + h5, .h1.red + h5, h1.red + .h5, .h1.red + .h5 { margin-top: -7px; margin-left: -28px; }

// End-Blocks
h6 { font-weight: 700; font-size: $font-size-base; line-height: $line-height-base;
  &.red {
	color: $sg-red;
	a, a:visited { color: $sg-red; text-decoration: underline; }
	a:hover; a:focus { color: $sg-red-light; text-decoration: none; }
  }
}

// Big Section Headers for Regular Text 
h3.red, .h3.red { color: $sg-red; font-weight: 700; font-size: 17.7px; margin-top: 42px; margin-bottom: 14px; letter-spacing: 0.014em;
	padding: 0 7px 2px 7px; line-height: 27px; margin-left: -7px; margin-right: -7px; border-bottom: 1px solid $sg-4; }

// Blockquote
blockquote { font-size: 19px; line-height: 30px; border-left: 2px solid $sg-3; font-family: $font-family-allumi; letter-spacing: 0.01em; color: $sg-8; padding-left: 25px;
  @media (min-width: $screen-sm-min) { margin-left: -28px; }
  em { font-style: italic; }
  p:last-child { margin-bottom: 7px; }
}
blockquote .small, blockquote footer, blockquote small { color: $sg-6; font-size: 12px; line-height: 17px; letter-spacing: 0.02em; }

// Text
p, ul, ol {
  @media (min-width: $screen-lg-min) { font-size: 15.42px; line-height: 27px; }
}
ul, ol {
  &.small { font-size: 14px; line-height: 24px; }
}
main ul { list-style: none; padding-left: 21px;
  li { position: relative; 
	&:before { content: '+'; position: absolute; left: -21px; }
  }
}
main ul.list-inline, main ul.plain {
  li {
    &:before { content: ''; left: 0; }
  }
}
main ul.plain { padding-left: 0; }
main ul.numbers {
  a { color: $sg-black; }
}
main ul.socialmedia {
  li { padding-right: 7px; }
}
.gform_wrapper ul li {
	&:before { content: ''; position: relative; left: auto; }
}
i, em { color: $sg-red; font-style: normal; }
hr { margin-left: -7px; margin-right: -7px; border-top: 1px solid $sg-4;
  &.black { border-top: 2px solid $sg-7; }
  &.red { border-top: 2px solid $sg-red; }
}	

// Tables
.table > thead > tr > th { border-bottom: 2px solid $sg-7; }
.table > tbody > tr > td { border-bottom: 1px solid $sg-4; }
.table-hover > tbody > tr:hover { background: $sg-1; }
.table-striped > tbody > tr:nth-of-type(2n+1) { background: $sg-1; }
.table-striped.table-hover > tbody > tr:nth-of-type(2n+1):hover { background: $sg-2; }

// Page Segments
.segment { padding-top: 116px; }
.hashlink:before { display: block; position: relative; margin-top: -35px; height: 35px; content: " "; visibility: hidden; }

// Regular Pages
.main { padding-top: 114px; }

// Header
header { position: relative; }
.container-holder { position: absolute; width: 100%; z-index: 77; }
.verticalspacer { height: 42px;
  @media (max-width: $screen-xs-max) { height: 24px; }
}
.ubermenu-sticky-toggle-wrapper {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { display: none !important; }
}
.ubermenu-loc-primary_navigation { font-size: 12px; color: $sg-7; box-shadow: 0 0 7px rgba(0, 0, 0, 0.14); }
.ubermenu-loc-primary_navigation .ubermenu-target { color: $sg-9; }
.ubermenu-loc-primary_navigation .ubermenu-target-description { color: $sg-5; }
.ubermenu-loc-primary_navigation {
  background-color: $sg-2;
  @include gradient-vertical($start-color: $sg-3, $end-color: $sg-1, $start-percent: 0%, $end-percent: 100%); filter: none !important;
}
.ubermenu-loc-primary_navigation.ubermenu-responsive-toggle { background: $sg-2;
  @include gradient-vertical($start-color: $sg-3, $end-color: $sg-3, $start-percent: 0%, $end-percent: 100%); filter: none !important;
  text-transform: none; font-style: normal; letter-spacing: 0.07em; font-size: 17px; color: $sg-8; font-weight: 700; text-transform: uppercase; }
.ubermenu-loc-primary_navigation.ubermenu-responsive-toggle:hover {
  color: $sg-black; background: $sg-3; transition: background 300ms, color 200ms;
  @include gradient-vertical($start-color: $sg-4, $end-color: $sg-2, $start-percent: 0%, $end-percent: 100%); filter: none !important;
}
/* 
.ubermenu-loc-primary_navigation .ubermenu-item-level-0.ubermenu-current-menu-item > .ubermenu-target,
.ubermenu-loc-primary_navigation .ubermenu-item-level-0.ubermenu-current-menu-parent > .ubermenu-target,
.ubermenu-loc-primary_navigation .ubermenu-item-level-0.ubermenu-current-menu-ancestor > .ubermenu-target {
  color: #ffff00; background: #000099; } */
// .ubermenu-loc-primary_navigation .ubermenu-item.ubermenu-item-level-0 > .ubermenu-highlight { color: #ff00ff; background: blue; }
.ubermenu-loc-primary_navigation .ubermenu-item-level-0 > .ubermenu-target {
  color: $sg-7; font-style: italic; text-transform: uppercase; font-size: 12px; letter-spacing: 0.02em; font-family: $font-family-allumi; border-left: 1px dotted $sg-4; }
.ubermenu-loc-primary_navigation .ubermenu-item-110 > .ubermenu-target { border-right: 1px dotted $sg-4; }
.ubermenu-loc-primary_navigation .ubermenu-target { outline: none; }
.ubermenu-main .ubermenu-item-level-0 > .ubermenu-target { padding-top: 10px; padding-bottom: 10px; }

.ubermenu-loc-primary_navigation .ubermenu-item-level-0:hover > .ubermenu-target {
  color: $sg-black; background: $sg-3; transition: background 300ms, color 200ms;
  @include gradient-vertical($start-color: $sg-4, $end-color: $sg-2, $start-percent: 0%, $end-percent: 100%); filter: none !important; 
}
.ubermenu-loc-primary_navigation .ubermenu-item-level-0.ubermenu-active > .ubermenu-target {
  color: $sg-white; background: $sg-6; transition: background 300ms, color 200ms;
  @include gradient-vertical($start-color: $sg-7, $end-color: $sg-6, $start-percent: 0%, $end-percent: 100%); filter: none !important;
}
// .ubermenu-loc-primary_navigation .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item > .ubermenu-target { color: #ff0; }
// .ubermenu-loc-primary_navigation .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item > .ubermenu-target:hover { color: #f0f; }
.ubermenu-loc-primary_navigation .ubermenu-submenu.ubermenu-submenu-drop { background: lighten($sg-2, 3%); border: 1px dotted $sg-4; font-family: $font-family-allumi; text-transform: uppercase; font-style: italic; font-weight: 500; color: $sg-7; }
.ubermenu-loc-primary_navigation.ubermenu-horizontal .ubermenu-item-level-0 > .ubermenu-submenu-drop { border-top: none; }
// .ubermenu-loc-primary_navigation .ubermenu-tab-content-panel { background: #0ff; }
.ubermenu-loc-primary_navigation .ubermenu-submenu,
.ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-target { color: $sg-7; text-transform: uppercase; letter-spacing: 0.02em; font-size: 12px; }
// .ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-highlight { color: #f0f; }
.ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-target:hover { color: $sg-black; background: $sg-3; }
.ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-active > .ubermenu-target { background: lighten($sg-6, 3%); color: $sg-white; }
.ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-target > .ubermenu-target-description { color: $sg-7; }
.ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-active > .ubermenu-target > .ubermenu-target-description { color: $sg-7; }
// .ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-item-header > .ubermenu-target { color: #f50; }
// .ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-item-header.ubermenu-has-submenu-stack > .ubermenu-target { border-bottom: 1px solid #f0f; }
// .ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-item-header > .ubermenu-target:hover { color: #0ff; }
// .ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-current-menu-item > .ubermenu-target { color: #0f5; background: #ff0; }
/* .ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-tabs .ubermenu-tab:hover > .ubermenu-target,
 .ubermenu-loc-primary_navigation .ubermenu-submenu .ubermenu-tab.ubermenu-active > .ubermenu-target { background: #90d; color: #111111; }
/*
	.ubermenu-loc-primary_navigation .ubermenu-tabs-group,
.ubermenu-loc-primary_navigation .ubermenu-tab > .ubermenu-target,
.ubermenu-loc-primary_navigation .ubermenu-tab-content-panel { border-color: #01f; } */
// .ubermenu-loc-primary_navigation .ubermenu-submenu-drop { border-bottom-width: 3px; }
.ubermenu-loc-primary_navigation .ubermenu-item .ubermenu-submenu-drop { -webkit-box-sizing: content-box; -moz-box-sizing: content-box; -o-box-sizing: content-box; box-sizing: content-box; }
/* Drop Sub - Left Edge of Menu Bar */
.ubermenu-loc-primary_navigation.ubermenu-horizontal .ubermenu-submenu-drop.ubermenu-submenu-align-left_edge_bar,
.ubermenu-loc-primary_navigation.ubermenu-horizontal .ubermenu-submenu-drop.ubermenu-submenu-align-full_width { left: -1px; }
.ubermenu-loc-primary_navigation.ubermenu-horizontal .ubermenu-submenu-drop.ubermenu-submenu-align-right_edge_bar { right: -1px; }
.ubermenu-loc-primary_navigation.ubermenu-horizontal .ubermenu-submenu-drop.ubermenu-submenu-align-full_width.ubermenu-submenu-padded { width: calc(103%); }
.ubermenu-loc-primary_navigation.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-submenu-drop,
.ubermenu-loc-primary_navigation.ubermenu-nojs.ubermenu-vertical .ubermenu-item-level-0:hover > .ubermenu-submenu-drop,
.ubermenu-loc-primary_navigation.ubermenu-vertical .ubermenu-item-level-0.ubermenu-active > .ubermenu-submenu-drop,
.ubermenu-loc-primary_navigation.ubermenu-vertical .ubermenu-active .ubermenu-submenu.ubermenu-submenu-type-flyout .ubermenu-submenu-type-mega { top: -1px; }
.ubermenu-loc-primary_navigation.ubermenu-horizontal .ubermenu-item-level-0.ubermenu-active:after { content: ''; border-bottom: 1px solid transparent; width: 100%; width: calc(101%); margin-bottom: -1px; }
.ubermenu-loc-primary_navigation.ubermenu-horizontal .ubermenu-item-level-0.ubermenu-active > .ubermenu-submenu-drop,
.ubermenu-loc-primary_navigation.ubermenu-horizontal:not(.ubermenu-transition-shift) .ubermenu-item-level-0 > .ubermenu-submenu-drop { margin-top: 0; border-top: 2px $sg-red solid; }
.ubermenu-loc-primary_navigation.ubermenu-vertical:before { content: ''; position: absolute; top: 0; right: -1px; height: 100%; border-right: 1px solid #0ff; z-index: 100; width: 0; }




// Intro
#intro { height: 77%; padding: 0; position: relative; color: #fff; background: transparent;
  // Full Girl Parallax (tm)
  #parallax-plane { height: 100%; z-index: -1 !important; top: -40%; }
  .logo-holder { position: absolute; top: 113px; width: 100%; z-index: 7; }
  .holder { position: absolute; bottom: 7%; width: 100%; z-index: 3; }
  .partner-holder { position: absolute; bottom: 7%; width: 100%; z-index: 4; }
  .partner-logo-holder { position: relative; }
  .partner-logo { width: 180px; height: auto; position: absolute; bottom: 7px; z-index: 4; background: $sg-9; background: rgba(0,0,0,.42); display: block;
    @media (min-width: $screen-lg-min) { left: 0; }
    @media (min-width: $screen-md-min) and (max-height: 530px ){ display: none !important; }
    span { display: block; }  
  }
  #airbus-partner { z-index: 5; }
  #autogyro-partner { z-index: 6; }
  .backstretch { background: transparent; }
  h1 { font-size: 27px; line-height: 38px; font-style: italic; font-weight: 700; letter-spacing: 0.042em; text-transform: uppercase; padding-left: 20px;
	@media (max-width: $screen-xs-max) { font-size: 20px; line-height: 28px; padding-left: 0; }
	text-shadow: 1px 1px 4px rgba(0,0,0,.77);
  }
  h4 { text-shadow: 1px 1px 4px rgba(0,0,0,.42);
	a, a:visited { color: $sg-red;
	  &:hover { color: $sg-red-light; text-decoration: none; }
	}
  }
}

// Jobs
.jobs h2 {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { margin-right: -42px; }
}

// Home
#gallery { background: $sg-6; min-height: 916px; }
#container { background: $sg-black; min-height: 916px; margin: 0 auto;
  .description { background: $sg-red-dark; color: $sg-white; padding: 28px 28px 28px 21px;
	h2 { font-family: $font-family-allumi; font-size: 19px; line-height: 26px; font-style: italic; font-weight: 500; margin: 0 0 10px 0; text-align: left; }
	h3 { color: #fcfcfc; font-family: $font-family-allumi; font-size: 17px; font-style: italic; font-weight: 500; text-transform: uppercase; }
	h4 { color: darken($sg-red-darker, 7%); font-size: 14px; letter-spacing: 0.02em; margin: 27px 0 0 0;
      a, a:visited { color: darken($sg-red-darker, 14%); text-decoration: none; }
      a:hover, a:focus { color: #fff; text-shadow: 0 0 2px rgba(255,255,255,0.69); text-decoration: none; }
	}
	p { font-size: 14px; line-height: 24px; margin: 0; padding: 0 0 12px 0; }
  }
  @media (max-width: $screen-xs-max) {
    .description { padding: 10px 10px 10px 3px;
	  h2 { font-size: 13.7px; line-height: 23px; }
      h4 { margin: 14px 0 0 0; font-size: 12px; line-height: 21px; }
      p { font-size: 12px; line-height: 23px; padding: 0 0 7px 0; }
    }
  }
  .block { border-left: 1px dotted $sg-2; padding: 10px 0 0 10px;
    @media (max-width: $screen-xs-max) { padding: 5px 0 0 7px; }
  }
}

.item { width: 50%; float: left; position: relative;
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { width: 33.33333%; }
  @media (min-width: $screen-lg-min) and (max-width: 1600px) { width: 25%; }
  @media (min-width: 1601px) { width: 20%; }
  img { width: 100%; height: auto; display: inline-block; }
  .logo-7g-red, .logo-7g-white {
	img { width: 56%;
      @media (max-width: $screen-xs-max) { width: 63%; }
	}
  }
  .logo-airbus {
	img { width: 77%;
      @media (max-width: $screen-xs-max) { width: 90%; }
	}
  }
  .logo-turbomeca {
	img { width: 77%; }
  }
  .logo-rotax {
	img { width: 70%; }
  }
  .logo-autogyro {
	img { width: 67%; }
  }
  .lightbox { display: block;
//	&:hover { position: absolute; box-shadow: 3px 3px 0 rgba(255,0,0,1); transition: margin 0.17s ease; margin: -3px; z-index: 5; }
    &:hover .overlay { opacity: 0.14; transition: opacity 0.17s ease; }
    .overlay { background: #000; width: 100%; height: 100%; position: absolute; z-index: 7; bottom: 0; opacity: 0; }
  }
  .fade25 { opacity: 0.75; }
  .fade50 { opacity: 0.5; }
  .fade75 { opacity: 0.25; }
  
  .tile { display: block;
	h2 { font-family: $font-family-allumi; color: $sg-white; text-transform: uppercase; font-style: italic; font-size: 1.42em; font-weight: 500; margin: 0 0 14px; letter-spacing: 0.02em;
	  @media (max-width: $screen-xs-max) { font-size: 17px; line-height: 24px; margin: 0 0 7px; }
	}
	h3 { font-family: $font-family-allumi; color: $sg-black; text-transform: uppercase; font-size: 1em; font-weight: 300; margin: 0;
	  @media (max-width: $screen-xs-max) { font-size: 13px; line-height: 21px; }
	}
	.caption { font-family: $font-family-allumi; font-weight: 500; position: absolute; z-index: 7; bottom: 0.7em; right: 14px; font-size: 12px;
	  @media (max-width: $screen-xs-max) { font-size: 10.7px; right: 7px; line-height: 14px; }
	}
	&:hover {
	  h2 { color: #fff; text-shadow: 0 0 3px rgba(255,255,255,0.69); }
	}
  }
  .tile-red { background: $sg-red;
	.caption { color: $sg-red-darker; }	  
  }
  a.tile-red:hover { background: $sg-red-light;
    .caption { color: #fff; }    
  }
  .tile-white {
    background: $sg-1;
    @include gradient-vertical($start-color: $sg-1, $end-color: #fff, $start-percent: 0%, $end-percent: 100%);
    .caption { color: $sg-2; }
  }
  a.tile-white:hover { background: #fff;
    .caption { color: $sg-black; }
  }
  .tile-gray {
    background: $sg-3;
    @include gradient-vertical($start-color: $sg-3, $end-color: $sg-2, $start-percent: 0%, $end-percent: 100%);
    .caption { color: $sg-5; }
  }
  a.tile-gray:hover { background: $sg-2;
    .caption { color: $sg-black; }
  }
  .tile-gray-dark {
    background: $sg-black;
    @include gradient-vertical($start-color: $sg-black, $end-color: $sg-9, $start-percent: 0%, $end-percent: 100%);
    .caption { color: darken($sg-6, 4.2%); }
  }
  a.tile-gray-dark:hover { background: $sg-7;
    .caption { color: $sg-1; }
  }
  .tile-blue {
    background: $sg-blue-light;
    @include gradient-vertical($start-color: $sg-blue-light, $end-color: $sg-blue-lighter, $start-percent: 0%, $end-percent: 100%);
    .caption { color: $sg-blue-dark; opacity: 0.7; }
  }
  a.tile-blue:hover { background: $sg-blue-lighter;
    .caption { color: $sg-black; opacity: 0.8; }
  } 
  .tile-blue-dark {
    background: $sg-blue-darker;
    @include gradient-vertical($start-color: $sg-blue-darker, $end-color: $sg-blue-dark, $start-percent: 0%, $end-percent: 100%);
    .caption { color: $sg-blue; }
  }
  a.tile-blue-dark:hover { background: $sg-blue-dark;
    .caption { color: $sg-1; }
  }
}
.sg-login { background: darken($sg-red-dark, 3%); color: $sg-white; }
.form-holder { padding: 28px 28px 28px 21px;
  @media (max-width: $screen-xs-max) { padding: 10px 10px 10px 10px; }
  h3 { font-family: $font-family-allumi; font-size: 19px; font-style: italic; font-weight: 500; line-height: 26px; margin: 0 0 10px; text-align: left;
	@media (max-width: $screen-xs-max) { font-size: 16px; line-height: 26px; }
  }
  .gform_wrapper .gfield_error .gfield_label { color: lighten($sg-red-light, 7%); text-shadow: 0 0 4px rgba(0, 0, 0, 0.07); }
  .validation_error { margin-bottom: 7px; box-shadow: 0 0 4px rgba(0, 0, 0, 0.07);
	@media (max-width: $screen-xs-max) { font-size: 12px; line-height: 21px; padding: 7px; }
  }
  .gform_description { line-height: 24px; color: darken($sg-red-darker, 7%); font-size: 14px; letter-spacing: 0.02em; font-weight: 700;
	@media (max-width: $screen-xs-max) { font-size: 12px; line-height: 21px; }
    a, a:visited { color: darken($sg-red-darker, 14%); text-decoration: none; }
    a:hover, a:focus { color: #fff; text-shadow: 0 0 2px rgba(255,255,255,0.69); text-decoration: none; }
  }
  
}
.overlay-logo { position: absolute; width: 100%; height: 420px; bottom: 0; pointer-events: none; z-index: 7;
  background: transparent url('/app/themes/7group.aero/dist/images/ie-gradient.png') repeat-x;
 
  background: -moz-linear-gradient(top, rgba(0,0,0,0), $sg-black 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,$sg-black)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,$sg-black 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,$sg-black 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,$sg-black 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,$sg-black 100%); /* W3C */
// no IE9 support for transparent gradients! 
// @include gradient-vertical($start-color: rgba(0,0,0,0), $end-color: $sg-black, $start-percent: 0%, $end-percent: 100%);
  h2, h3 { color: $sg-white; }
  h2, h3, .btn { pointer-events: all; }
  h3 + h3 { margin: 10px 0 42px 0; }
  h3 {
    @media (max-width: $screen-xs-max) { font-size: 16px; line-height: 26px; }
  }
  .container-holder { position: absolute; width: 100%; bottom: 0; }
}

// Imprint
#imprint { color: $sg-white; text-align: center; background: $sg-black; padding-top: 42px; padding-bottom: 42px; display: none;
  h2, .h2 { margin-left: 0; margin-bottom: 0; }
  h3, .h3 { color: $sg-6; }
  h4, .h4 { font-style: normal; margin-top: 14px;
    a { color: $sg-white;
	  &:hover { color: #fff; text-shadow: 0 0 2px rgba(255,255,255,.042); text-decoration: none; }
    }
  }
  hr { width: 4.2em; margin-left: auto; margin-right: auto; }
  small { font-size: 1em; color: $sg-7; }
  blockquote { padding-left: 0; border-left: 0; padding-right: 0; margin-left: 0; }
}

// Footer
.content-info { padding-top: 42px; padding-bottom: 21px; color: $sg-5;
  a, a:visited { color: $sg-5; text-decoration: underline; }
  p { line-height: 21px; }
  small { display: inline-block; font-size: 12px; }
  .home & { color: $sg-7;
	a, a:visited { color: $sg-7; }
	a:hover, a:focus { color: $sg-6; }
	.fb-like { transform: scale(1.4); -ms-transform: scale(1.4); -webkit-transform: scale(1.4); -o-transform: scale(1.4); -moz-transform: scale(1.4); margin: 14px 0 21px 0;
      @media (max-width: $screen-xs-max) { transform: scale(1.2); -ms-transform: scale(1.2); -webkit-transform: scale(1.2); -o-transform: scale(1.2); -moz-transform: scale(1.2); padding-bottom: 21px; margin-bottom: 21px; }
    }
  }
  .fb-like { margin: 7px 0 7px 0; }
}

// Forms
label { margin-left: 4px; }
.ginput_complex label, .gfield_description { color: $sg-7; font-size: 14px; margin: 3px 0 17px 4px !important; }
.ginput_container input[type=text],
.ginput_container input[type=email],
.ginput_container input[type=number],
.ginput_container input[type=url],
.ginput_container input[type=tel],
.ginput_container input[type=date],
.ginput_container input[type=password],
.ginput_container select,
.ginput_container textarea { border-radius: 0; &:focus { background: $sg-3; color: black; } }
.gform_wrapper .gfield_checkbox li input[type="checkbox"] { float: left; }
.gform_wrapper .gfield_checkbox li label, .gform_wrapper .gfield_radio li label {
    display: block;
    line-height: 1.5;
    margin: 0 0 0 24px;
    padding: 0;
    vertical-align: top;
    width: auto;
}
.gform_wrapper {
  .alert-danger, .validation_error { background: $sg-red-light; border-color: $sg-red-light; color: $sg-white; font-weight: 700; }
}


//
//	Folkhack's Gravity Forms styling
//
//	Table of Contents:
//	- Variables/configuration
//	- General fixes
//	- Required *
//	- input, select, and textarea elemnt extends
//	- Button extend
//	- Error/notification messages handling
//	- Gravity Forms complex containers
//	- Weird-ass clear-multi shit
//	- Date fields
//	- Lists
//	- General Datepicker
//	- Folkhack's jQuery UI Datepicker: (DEPRECIATED!)
//  - Validation container
//
//
//	Based on:
//	- http://roots.io/style-gravity-forms-with-bootstrap/
//	- https://gist.github.com/Gavrisimo/8406309
//	- https://gist.github.com/spigists/6796258
//
//	TODO:
//	- Clean this up once Gravity Forms more-or-less gets their shit more together
//	- Rip apart provided Gravity Forms CSS and implement all specific feature-sets
//	- Better handling of date-fields, currently drop-down dates are AOK to use
//

//
//	Variables/configuration:
//

// Enables/disables jQuery UI datepicker (jQuery UI can go die in a fire...IMHO)
$fh_gf_jqueryui_datepicker_enabled: false !default;
// Setup a reasonable responsive break-point for complex fields
$fh_gf_responsive_break_at: $screen-sm-min !default;


//
//	General fixes:
//

// Field/form container styling:
.gform_wrapper ul { @extend .list-unstyled; }
.gform_wrapper li { @extend .form-group; }
.gform_wrapper form { margin-bottom: 0; }

// Field container clear
.gfield { clear: both; }

// Form description
.gform_heading .gform_description {
	display: block;
	margin: 0 0 10px 0;
}

// Radios/checks
.gfield_checkbox,
.gfield_radio {
	margin: 0 0 0 10px;
	label {
		font-weight: normal;
		margin-left: 10px;
	}
	li { margin-bottom: 0px; }
}
.gfield_checkbox { margin-left: 0; }

// Multiple select and textarea height fixes
.ginput_container select[multiple] { height: auto; }
.ginput_container textarea { height: auto; }


//
//	Required *:
//
.gform_wrapper .gfield_required {
	color: $state-danger-text;
	padding-left: 1px;
}


//
//	input, select, and textarea elemnt extends:
//
.ginput_container input[type=text],
.ginput_container input[type=email],
.ginput_container input[type=number],
.ginput_container input[type=url],
.ginput_container input[type=tel],
.ginput_container input[type=date],
.ginput_container input[type=password],
.ginput_container select,
.ginput_container textarea {
	// @extend .form-control;
  display: block;
  width: 100%;
  height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid $input-border;
  border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus;

  // Placeholder
  @include placeholder;

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: $input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }


  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
  }

	background: $sg-1;
	border: none;
	box-shadow: none;
}


//
//	Button extend:
//
.gform_button {
	@extend .btn;
	@extend .btn-primary;
}

//
//	Error/notification messages handling:
//
.gform_wrapper .gfield_error {
	.gfield_label { color: $state-danger-text; }
	input,
	select,
	textarea {
		background-color: $alert-danger-bg;
		border-color: $alert-danger-border;
		color: $alert-danger-text;
		@include form-control-focus( $alert-danger-text );
	}
}
.validation_error {
	@extend .alert;
	@extend .alert-danger;
}
.validation_message {
	margin-top: 5px;
}
#gforms_confirmation_message { @extend .alert; }

.gfield_description.validation_message { display: none; }
//
//	Gravity Forms complex containers:
//	- based on discussion from http://roots.io/style-gravity-forms-with-bootstrap/
//	- HELLA refactored to SCSS/responsive sanity =/
//
.ginput_complex {

	// Add a 10px gray border to the left side of complex groupings
	display: block;
	padding: 10px 0 0 0;

	// Fix label font weight and top spacing
	label {
		font-weight: normal;
		margin: 5px 0 15px 0;
	}
	span:last-of-type label { margin: 5px 0 0 0; }

	// Full-width container
	.ginput_full { width: 100%; }

	// Left/right containers (ex: first/last name)
	.ginput_left,
	.ginput_right,
	.name_first,
	.name_last {
		float: left;
		width: 49%;
	}
	.ginput_left, .name_first { margin-right: 1%; }
	.ginput_right, .name_last { margin-left: 1%; }

    @media (max-width: $screen-xs-min) {
 	  .ginput_left,
	  .ginput_right,
	  .name_first,
	  .name_last {
		float: none;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	  }
	}
  
	// Inputs and labels
	.ginput_full {
		input,
		label {
			width: 100% !important;
		}
	}
	.ginput_left {
		input,
		select,
		textarea,
		label {
			width: 95% !important;
		}
	}

	// Responsive break for full-width input fields
	@media screen and ( max-width: $fh_gf_responsive_break_at ) {
		.ginput_full,
		.ginput_left,
		.ginput_right {
			float: none;
			width: 100% !important;
			input { width: 100% !important; }
			label { width: 100% !important; }
		}
	}
}

// Add BS3 clearfix
.gf_clear_complex { @extend .clearfix; }


//
//	Weird-ass clear-multi shit:
//
.clear-multi {

	display: table;

	// Add BS3 clearfix
	@extend .clearfix;

	// Time/date fields
	// - consider refactoring to be wrapped in .gfield_time_* and .gfield_date_*
	//   keeping non-wrapped due to compiled CSS bloat. Keep an eye on this!
	.ginput_container {
		display: table-cell;
		padding-left: 5px;
		input { width: 70px; }
		select { width: 80px; }
		// Disable the labels for time (yeahhh no need really)
		label { display: none; }
	}
	.ginput_container:first-child { padding-left: 0; }

	// This is hack-y but fixes a rendering bug on Chrome
	// TODO: clean this up
	.gfield_time_hour,
	.gfield_time_minute,
	.gfield_time_ampm {
		float: left;
	}

	// Inline block so that input element aligns properly with ":"
	.gfield_time_hour input { display: inline-block; }

	//
	//	Date fields
	//
	.gfield_date_month,
	.gfield_date_day,
	.gfield_date_year {
		// Re-enable the date field labels
		label {
			display: block;
			font-weight: normal;
		}
	}
}


//
//	Lists:
//
.gfield_list {
	// Offset the top padding for the cell spacing
	margin-top: -5px;
	tr .gfield_list_cell { padding: 5px 0; }
	.gfield_list_icons {
		vertical-align: middle !important;
		width: 60px;
		img { float: left; }
	}
}


//
//	General Datepicker:
//
.gfield .datepicker {
	display: inline-block !important;
	width: 170px !important;
}

//
//	Folkhack's jQuery UI Datepicker: (DEPRECIATED!)
//	- hide jQuery UI datepicker in lieu of using the HTML type="date" option
//	- left this around just-in-case
//
@if( $fh_gf_jqueryui_datepicker_enabled ) {

	// Local defaults from "Folkhack's Boilerplate"
	//	* Allows for stand-alone use
	//	* TODO: nix this ... I think I'm totally done f'n with jQuery UI
	$k_black:      #000000 !default;
	$k_white:      #FFFFFF !default;
	$k_mineshaft:  #303030 !default;
	$k_silver:     #CCCCCC !default;
	$k_whitesmoke: #F5F5F5 !default;

	.ui-datepicker-trigger {
		display: inline-block;
		margin-left: 5px;
	}
	.ui-widget {
		background: $k_mineshaft;
		box-shadow: 1px 4px 9px $k_silver;
	}
	.ui-datepicker {
		font-family: Verdana, sans-serif;
		font-size: 90% !important;
		padding: 0;
		a:hover { text-decoration: none; }
		.ui-datepicker-header {
			background: transparent;
			border: none;
			font-weight: normal;
			padding: 3px;
			a {
				color: $k_white;
				text-transform: uppercase;
			}
		}
		.ui-datepicker-header .ui-datepicker-title { text-align: center; }
		.ui-datepicker-month {
			margin-right: 5px;
		}
		.ui-datepicker-next {
			float: right;
			right: 2px;
		}
		.ui-datepicker-prev {
			float: left;
			left: 2px;
		}
		.ui-datepicker-next,
		.ui-datepicker-prev {
			cursor: pointer;
			top: 3px;
			span {
				font-size: 10px;
				font-weight: normal;
				margin-top: 0;
				position: relative;
				top: 0;
			}
		}
		table { margin: 0; }
		th {
			border-top: 1px solid $k_silver;
			border: none;
			color: $k_silver;
			font-size: 13px;
			font-weight: normal;
			padding: 3px 0;
			text-align: center;
		}
		td {
			background: $k_whitesmoke;
			border: none;
			color: $k_mineshaft;
			padding: 0;
			text-align: center;
		}
		td .ui-state-default {
			background: transparent;
			border: none;
			display: block;
			margin: 0;
			padding: .5em;
			text-align: center;
		}
		td .ui-state-active,
		td .ui-state-hover {
			background: $k_white;
		}
		.ui-state-disabled  { opacity: 1; }
	}

} @else {
	
	// Hide jQuery UI datepicker
	.ui-datepicker,
	.ui-datepicker-trigger { display: none !important; }
}

// Honeypot validation container
// - hides .gform_validation_container
.gform_validation_container {
  display: none;
}
